import { LatLng } from "../types/map";
import React, { CSSProperties, useCallback } from "react";

export interface MarkerProps extends LatLng {
  color?: string;
  key: string;
  dotKey: string;
  onClick?: (key: string) => void;
  zoomLevel: number;
  totalPoints: number;
  size?: number;
  uniqueId?: string;
  uniqueMultiplier?: number;
}

const Marker: React.FC<MarkerProps> = ({
  color = "#F890AC",
  dotKey,
  onClick,
  zoomLevel,
  totalPoints = 1,
  size: rawSize = 6,
  uniqueId,
  uniqueMultiplier = 2
}) => {
  const handleClick = useCallback(() => onClick && onClick(dotKey), [dotKey, onClick]);

  let size = 48;

  if (zoomLevel >= 17) {
    size = size / 1;
  } else if (zoomLevel >= 15) {
    size = size / 2;
  } else if (zoomLevel >= 13) {
    size = size / 4;
  } else {
    size = size / 5;
  }

  // Normal dots have a shadow that will triple it's size, so divide by 3.
  const sizeComponent = Math.round(uniqueId ? size * uniqueMultiplier : size / 3);

  const styles: CSSProperties = {
    height: sizeComponent,
    width: sizeComponent,
  };
  if (!uniqueId) {
    styles.backgroundColor = color;
    // If we want blurred shadows.
    // styles.boxShadow = `0 0 2px ${size}px ${color}44`;
    styles.boxShadow = `0 0 0 ${sizeComponent}px ${color}44`;
  }

  return (
    <div className={`mapDot mapDot--${uniqueId || 'normal'}`} onClick={handleClick}>
      <div className="dot" style={styles} />
    </div>
  );
};

export default Marker;
