import React, { useRef } from "react";
import "./Name.scss";
import { useHistory } from "react-router-dom";
import Page from "../components/Page";
import PageProgress from "../components/PageProgress";

const Name = (props) => {
  const history = useHistory();

  const handleNext = () => {
    history.push("/message");
  };

  return (
    <Page className={"name-screen"}>
      <div>
        <PageProgress currentScreen={0} totalScreens={3} />
      </div>

      <div className={"center"}>
        <h2>What name would you like to show alongside your message?</h2>
      </div>
      <div className={"name"}>
        <div className={"name__input"}>
          <input
            id={"name"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.setName(e.target.value);
            }}
            placeholder={"Your preferred name"}
            value={props.name}
          />
        </div>
      </div>
      {props.name && (
        <div className={"actions"}>
          <button onClick={handleNext}>Continue</button>
        </div>
      )}
    </Page>
  );
};

export default Name;
