const colorCodes = [
  // "#FFCCE5", "#FFA6D1", "#FF91C6",
  // "#F8C6D3", "#F8AEC1", "#F895AF",
  // "#F888A6", "#F8638B", "#F84A79",

  "#ff97c7",
  "#f894a7",
  "#f744a7",
  "#ef5078",

  "#f6c4de",
  "#f87c98",
  "#f6a4b7",
  "#f06787",

  "#fab7cd",
  "#f784bf",
  "#fc56a9",

  "#ef98ba",
  "#efb8bf",
  "#fe00fa",
  "#f7138e",
];

export default colorCodes;
