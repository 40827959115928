import React from "react";
import "./PageProgress.scss";

const PageProgress = ({
  currentScreen,
  totalScreens,
}: {
  currentScreen: number;
  totalScreens: number;
}) => {
  const progressIndicators = Array(totalScreens).fill(false);
  progressIndicators[currentScreen] = true;
  return (
    <div className={"indicators"}>
      {progressIndicators.map((status, index) => {
        if (status) {
          return <div key={index} className={"indicator indicator--highlighted"} />;
        }
        return <div key={index} className={"indicator"} />;
      })}
    </div>
  );
};

export default PageProgress;
