import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, useHistory, Redirect } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";

import Hello from "./screens/Hello";
import Name from "./screens/Name";
import Message from "./screens/Message";
import Colours from "./screens/Colours";
import Location from "./screens/Location";
import ThankYou from "./screens/ThankYou";
import Map from './screens/Map';
import Holding from "./screens/Holding";
import ViewTracker from "./components/ViewTracker";

// import colorCodes from "./colorCodes";

const firebaseConfig = {
  apiKey: "AIzaSyDHEtoMuNKUfPfUhcZggd9Z3peAgf-hWgo",
  authDomain: "pinkdot2020-1bffa.firebaseapp.com",
  databaseURL: "https://pinkdot2020-1bffa.firebaseio.com",
  projectId: "pinkdot2020-1bffa",
  storageBucket: "pinkdot2020-1bffa.appspot.com",
  messagingSenderId: "1085949054457",
  appId: "1:1085949054457:web:74fd7474cc34d2d7a4c128",
  measurementId: "G-LEH1KHSDCP",
};

// Initialize Cloud Firestore through Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const App = () => {
  // If we want to randomly assign a color at the start.
  // To get a new color user will need to refresh the whole app.
  // const randomColorIndex = Math.round(Math.random() * (colorCodes.length - 1));
  // const randomColor = colorCodes[randomColorIndex];

  // eslint-disable-next-line no-restricted-globals
  const hostname = location?.hostname || "";
  let isDebugMode = false;
  if (
    process.env.NODE_ENV === "development" ||
    hostname === "localhost" ||
    hostname === "staging-loveliveshere.web.app"
  ) {
    isDebugMode = true;
  }

  const [mode, setMode] = useState("loading");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [selectedColor, setSelectedColor] = useState("");

  useEffect(() => {

    // Listen to realtime updates of one document.
    firebase.firestore().collection("stats").doc("status")
      .onSnapshot(function(doc) {
        const data = doc.data();
        console.log("Update on stats.status: ", data);

        if (!data) {
          setMode("checkin");
          return;
        }

        let screen = data.screen || "checkin";
        let refreshToken = data.refresh || "";
        if (refreshToken) {
          console.log("Got refresh token:", refreshToken);

          // Check if refresh token is in localStorage, if yes, don't need to refresh.
          const pastRefreshesJSON = window.localStorage.getItem('pastRefreshes') || "{}";
          const pastRefreshes = JSON.parse(pastRefreshesJSON);
          if (pastRefreshes[refreshToken]) {
            // Do nothing.
          } else {
            // Store token.
            pastRefreshes[refreshToken] = new Date().getTime();
            window.localStorage.setItem('pastRefreshes', JSON.stringify(pastRefreshes));

            // Refresh
            console.log("Refreshing");
            // Legacy way.
            window.location.reload(true);
            // window.location.href = window.location.href;
            return;
          }
        }

        setMode(screen);
      });

  }, []);

  const clearInputs = () => {
    // setName("");
    setMessage("");
    setSelectedColor("");
  };

  if (mode === "loading") {
    return <div></div>;
  }

  if (mode === "holding") {
    return (
      <Router>
        <Switch>
          {/* This is here so we can still access map by entering url directly. */}
          <Route path={"/map"}>
            <Map isDebugMode={isDebugMode} year="2021" />
          </Route>
          <Route path={"/"}>
            <Holding />
          </Route>
        </Switch>
      </Router>
    );
  }

  // if (mode === "map") {
  //   return (
  //     <Router>
  //       <Switch>
  //         <Route path={"/"}>
  //           <Map isDebugMode={isDebugMode} year="2021" />
  //         </Route>
  //       </Switch>
  //     </Router>
  //   )
  // }

  return (
    <Router>
      <ViewTracker>
        <Switch>
          <Route path={"/checkin"}>
            <Hello />
          </Route>
          <Route path={"/name"}>
            <Name name={name} setName={setName} />
          </Route>
          <Route path={"/message"}>
            <Message
              name={name}
              message={message}
              setMessage={setMessage}
              setSelectedColor={setSelectedColor}
            />
          </Route>
          <Route path={"/colours"}>
            <Colours
              name={name}
              message={message}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
            />
          </Route>
          <Route path={"/location"}>
            <Location
              name={name}
              message={message}
              selectedColor={selectedColor}
              clearInputs={clearInputs}
              isDebugMode={isDebugMode}
            />
          </Route>
          <Route path={"/thank-you"}>
            <ThankYou name={name} message={message} selectedColor={selectedColor} />
          </Route>
          <Route path={"/dot/2020/:dotKey"}>
            <Map isDebugMode={isDebugMode} year="2020" />
          </Route>
          <Route path={"/dot/2021/:dotKey"}>
            <Map isDebugMode={isDebugMode} year="2021" />
          </Route>
          <Route path={"/dot/:dotKey"}>
            <Map isDebugMode={isDebugMode} year="2020" />
          </Route>
          <Route path={"/map/2021"}>
            <Map isDebugMode={isDebugMode} year="2021" />
          </Route>
          <Route path={"/map/2020"}>
            <Map isDebugMode={isDebugMode} year="2020" />
          </Route>
          <Route path={"/map"}>
            <Redirect to="/map/2021" />
          </Route>
          <Route path={"/holding"}>
            <Holding />
          </Route>
          <Route path={"/"}>
            {/*<Hello />*/}
            <Redirect to="/map/2021" />
          </Route>
        </Switch>
      </ViewTracker>
    </Router>
  );
}

export default App;
