import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/analytics";

function usePageViews() {
  const location = useLocation();

  useEffect(() => {
    // @ts-ignore
    if (!window.gtag) return;

    const path = location?.pathname || "";

    const screenViewParams: any = {
      app_name: "pinkdot",
      screen_name: path,
      path: path,
    };

    const locationComponents = path.split("/");
    const level1Path = locationComponents[1];

    if (path === "/") {
      screenViewParams.screen_name = "map";
    } else if (level1Path === "map") {
      screenViewParams.screen_name = "map";
    } else if (level1Path === "dot") {
      screenViewParams.screen_name = "map";
      screenViewParams.dotKey = locationComponents[2];
    } else if (level1Path === "checkin") {
      screenViewParams.screen_name = "checkin";
      screenViewParams.step = "initial";
    } else if (level1Path === "name") {
      screenViewParams.screen_name = "checkin";
      screenViewParams.step = "name";
    } else if (level1Path === "message") {
      screenViewParams.screen_name = "checkin";
      screenViewParams.step = "message";
    } else if (level1Path === "colours") {
      screenViewParams.screen_name = "checkin";
      screenViewParams.step = "colours";
    } else if (level1Path === "location") {
      screenViewParams.screen_name = "checkin";
      screenViewParams.step = "location";
    } else if (level1Path === "thank-you") {
      screenViewParams.screen_name = "checkin";
      screenViewParams.step = "thank-you";
    }

    console.log("screenViewParams:", screenViewParams);

    // @ts-ignore
    // window.gtag('event', 'screen_view', screenViewParams);

    firebase.analytics().logEvent("screen_view", screenViewParams);
  }, [location]);
}

const ViewTracker = ({ children }) => {
  usePageViews();
  return children;
};

export default ViewTracker;
