import React, { CSSProperties, useCallback } from "react";
import { LatLng } from "../types/map";

export interface ClusterMarkerProps extends LatLng {
  color?: string;
  key: string;
  dotKey: string;
  onClick?: (key: string) => void;
  zoomLevel: number;
  isCluster: boolean;
  clusterId?: number;
  numberOfPoints?: number;
  totalPoints: number;
  size?: number;
  showClusterCount: boolean;
}

const ClusterMarker: React.FC<ClusterMarkerProps> = ({
  dotKey,
  onClick,
  zoomLevel,
  clusterId = 1,
  numberOfPoints = 1,
  totalPoints = 1,
  size: rawSize = 6,
  showClusterCount = false,
}) => {
  const handleClick = useCallback(() => onClick && onClick(dotKey), [dotKey, onClick]);

  let size = 0;

  let classNames = "cluster ";
  if (numberOfPoints >= 40) {
    classNames += "cluster-50";
    size = 600;
  } else if (numberOfPoints >= 30) {
    classNames += "cluster-40";
    size = 500;
  } else if (numberOfPoints >= 20) {
    classNames += "cluster-30";
    size = 350;
  } else if (numberOfPoints >= 10) {
    classNames += "cluster-20";
    size = 250;
  } else {
    classNames += "cluster-10";
    size = 150;
  }

  if (zoomLevel >= 17) {
    size = Math.round(size / 1);
  } else if (zoomLevel >= 15) {
    size = Math.round(size / 2);
  } else if (zoomLevel >= 13) {
    size = Math.round(size / 4);
  } else {
    size = Math.round(size / 5);
  }

  if (showClusterCount) {
    classNames += " cluster--indicators";
  }
  classNames += ` cluster-bucket--${clusterId % 10}`;

  const style: CSSProperties = { height: size, width: size };

  return (
    <div className="mapDot" onClick={handleClick}>
      <div className={classNames} style={style} />
      {showClusterCount && (
        <span className="count">
          {numberOfPoints} ({clusterId})
        </span>
      )}
    </div>
  );
};

export default ClusterMarker;
