import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Page from "../components/Page";
import MapMarkerColor from "../components/MapMarkerColor";
import "./Colours.scss";
import PageProgress from "../components/PageProgress";
import colorCodes from "../colorCodes";

const Colours = (props) => {
  const history = useHistory();

  useEffect(() => {
    if (!props.name) {
      history.push("/");
      return;
    }

    if (!props.message) {
      history.push("/message");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedValidColor = colorCodes.find((code) => props.selectedColor)
    ? true
    : false;

  return (
    <Page className={"colors-screen"}>
      <div>
        <PageProgress currentScreen={2} totalScreens={4} />
      </div>
      <h2>Next, choose a ‘pink dot’ that represents YOU!</h2>
      <div className={"colors"}>
        {colorCodes.map((colorCode) => {
          return (
            <div
              className={`color ${
                props.selectedColor === colorCode ? "color--selected" : ""
              }`}
              onClick={() => {
                props.setSelectedColor(colorCode);
              }}
            >
              <div className={"color__marker"}>
                <MapMarkerColor color={colorCode} dotColor={"#FFEEF3"} />
              </div>
            </div>
          );
        })}
      </div>
      {selectedValidColor && (
        <div className={"actions"}>
          <Link to={"/location"}>
            <button>Continue</button>
          </Link>
        </div>
      )}
    </Page>
  );
};

export default Colours;
