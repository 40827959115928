import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// console.log('process.env.GA_MEASUREMENT_ID:', process.env.GA_MEASUREMENT_ID);

// eslint-disable-next-line no-restricted-globals
const hostname = location?.hostname || "";
if (
  process.env.NODE_ENV !== "development" &&
  hostname !== "staging-loveliveshere.web.app"
) {
  console.log = () => {};
}
console.log("Environment:", process.env.NODE_ENV);

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
