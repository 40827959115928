import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Page from "../components/Page";
import "./Message.scss";
import PageProgress from "../components/PageProgress";
import colorCodes from "../colorCodes";

const Message = (props) => {
  const history = useHistory();

  useEffect(() => {
    if (!props.name) {
      history.push("/");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = () => {
    const randomColorIndex = Math.round(Math.random() * (colorCodes.length - 1));
    const randomColor = colorCodes[randomColorIndex];
    props.setSelectedColor(randomColor);
    history.push("/location");
  };

  return (
    <Page className={"message-screen"}>
      <div>
        <PageProgress currentScreen={1} totalScreens={3} />
      </div>

      <div className={"center"}>
        <h2>
          Thanks {props.name}! Please write a message of support for the LGBTQ
          community, particularly those who are feeling isolated and struggling
          with various challenges.
        </h2>
      </div>
      <div className={"center"}>
        <textarea
          placeholder={
            "Express your love and support, give a shoutout or share a sincere wish!"
          }
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            props.setMessage(e.target.value);
          }}
          defaultValue={props.message}
          maxLength={140}
        />
      </div>
      <div className={"actions"}>
        {props.message && <button onClick={handleNext}>Continue</button>}
      </div>
      <div className={"actions__note"}>
        Please be respectful of others. Do not post content that contains
        profanity, hate speech or personal information. Your message will be
        viewable when someone clicks on your dot on the map.
      </div>
    </Page>
  );
};

export default Message;
