import React, { useEffect } from "react";
import { LatLng } from "../types/map";

export interface ActiveMarkerProps extends LatLng {
  dotKey?: string;
  zoomLevel: number;
  uniqueId?: string;
}

const ActiveMarker: React.FC<ActiveMarkerProps> = ({
                                                     dotKey: key, zoomLevel,
                                                     uniqueId
                                                   }) => {
  useEffect(() => {
    if (!key) {
      return;
    }

    console.log("Remove bounce class");
    const activeDot = document.getElementById("mapDot-pinkie");
    if (activeDot) {
      // activeDot.className = activeDot.className.replace(/\bbounce-on\b/,'bounce-off');
      activeDot.className = activeDot.className.replace(/\bbounce\b/, "");
    }

    setTimeout(() => {
      const activeDot = document.getElementById("mapDot-pinkie");
      if (activeDot) {
        // activeDot.className = activeDot.className.replace(/\bbounce-off\b/,'bounce-on');
        activeDot.className = activeDot.className + " bounce";
      }
      console.log("Add bounce class");
    }, 1);
  }, [key]);

  console.log("[ActiveMarker] key:", key);
  if (!key) {
    return null;
  }

  let token = 0;
  for (let i = 0; i < key.length; i++) {
    token += key.charCodeAt(i) * (i + 1);
  }
  console.log("[ActiveMarker] token:", token);
  const pinkieIndex = token % 11;
  console.log("[ActiveMarker] pinkieIndex:", pinkieIndex);

  return (
    <div className={`mapDot mapDot--${uniqueId || 'normal'} mapDot--active  zoom--${zoomLevel}`}>
      <div className={`pinkie pinkie--${pinkieIndex + 1}`} id="mapDot-pinkie" />
    </div>
  );
};

export default ActiveMarker;
