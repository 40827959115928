import React from "react";
import "./Hello.scss";
import { Link } from "react-router-dom";
import Page from "../components/Page";

const Hello = (props) => {
  return (
    <Page
      className={"hello-screen"}
      topContent={
        <div className={"top"}>
          <img className={"mascot"} src={"/images/mascot.gif"} alt="mascot" />
          <img className={"logo"} src={"/images/PD13logo.png"} alt="logo" />
        </div>
      }
    >
      <div className={"welcome-message center"}>
        <div className={"mb16"}>
          Thank you for joining the digital light-up to mark Pink Dot 13 and
          show your solidarity with the LGBTQ community and our allies across
          Singapore.
        </div>
        <div className={"mb16"}>What's needed:</div>
        <div className={"summary"}>
          <div className={"summary__row"}>
            <div className={"summary__bullet"}>1</div>
            Sign up with your preferred name
          </div>
          <div className={"summary__row"}>
            <div className={"summary__bullet"}>2</div>
            Write a message of support
          </div>
          <div className={"summary__row"}>
            <div className={"summary__bullet"}>3</div>
            Select a location (in Singapore) that best represents where you will
            be participating from
          </div>
        </div>
        <div className={"actions"}>
          <Link to={"/name"}>
            <button>Let's start</button>
          </Link>
          {/*<div className="secret-link-to-map">*/}
          {/*  <Link to={"/map"}>*/}
          {/*    <span>&nbsp;</span>*/}
          {/*  </Link>*/}
          {/*</div>*/}
        </div>
      </div>
    </Page>
  );
};

export default Hello;
