import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./ThankYou.scss";
import Page from "../components/Page";

const urlToShare = "https://loveliveshere.pinkdot.sg/";
const shareText =
  "Ready to make Pink Dot? Come join me to form the digital Pink Dot on 12 June with a massive online light-up!";

const ThankYou = (props) => {
  const history = useHistory();

  useEffect(() => {
    if (!props.name) {
      history.push("/");
      return;
    }

    if (!props.message) {
      history.push("/message");
      return;
    }

    if (!props.selectedColor) {
      history.push("/colours");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShareFB = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${urlToShare}`,
      "pop",
      "width=600, height=400, scrollbars=no",
    );
    return false;
  };

  const getTwitterShareUrl = () => {
    return `http://twitter.com/share?text=${shareText}&url=${urlToShare}&hashtags=pinkdot13,pinkdotsg,loveliveshere`;
  };

  const handleGoToMap = () => {
    history.push("/");
  };

  return (
    <Page
      className={"thankyou-screen"}
      topContent={
        <div className={"top"}>
          <img className={"mascot"} src={"/images/mascot.gif"} alt="mascot" />
          <img className={"logo"} src={"/images/PD13logo.png"} alt="logo" />
        </div>
      }
    >
      <div>
        <div className={"thankyou-message"}>
          Thank you for joining the digital light-up to mark Pink Dot 13 and
          show your solidarity with the LGBTQ community and our allies across
          Singapore.
        </div>
        {/* <button onClick={handleGoToMap} style={{ textTransform: "uppercase" }}>
          Read what others wrote
        </button> */}
      </div>

      <div className={"mt24"}>
        <h3 className={"mb8"}>Share this</h3>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          onClick={handleShareFB}
          rel="noopener noreferrer"
          className={"share pinkdotsgfb"}
        >
          &nbsp;
        </a>
        <a
          href={getTwitterShareUrl()}
          target="_blank"
          rel="noopener noreferrer"
          className={"share pinkdotsgtw"}
        >
          &nbsp;
        </a>
      </div>

      <div className={"mt24"}>
        <h3 className={"mb8"}>Learn more about PinkDot SG</h3>
        <a
          href="https://pinkdot.sg/"
          target="_blank"
          rel="noopener noreferrer"
          className={"share pinkdotsgweb"}
        >
          &nbsp;
        </a>
        <a
          href="https://www.facebook.com/pinkdotsg/"
          target="_blank"
          rel="noopener noreferrer"
          className={"share pinkdotsgfb"}
        >
          &nbsp;
        </a>
        <a
          href="https://www.instagram.com/pinkdotsg"
          target="_blank"
          rel="noopener noreferrer"
          className={"share pinkdotsgig"}
        >
          &nbsp;
        </a>
        <a
          href="https://www.youtube.com/user/pinkdotsg"
          target="_blank"
          rel="noopener noreferrer"
          className={"share pinkdotsgyt"}
        >
          &nbsp;
        </a>
        <a
          href="https://www.twitter.com/pinkdotsg"
          target="_blank"
          rel="noopener noreferrer"
          className={"share pinkdotsgtw"}
        >
          &nbsp;
        </a>
      </div>
      <div className={"mt24"}>
        <h3 className={"mb8"}>
          This platform is developed with the{" "}
          <a href="mailto:we@foundin.org">Foundin</a> team.
        </h3>
      </div>
    </Page>
  );
};

export default ThankYou;
